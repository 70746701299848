.chips {
    margin-top: 2rem;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 0.5rem;
}

.chip {
    margin: 0;
    padding: calc(var(--page-padding) / 3) var(--page-padding);

    background: var(--color-light);

    text-decoration: none;

    width: min-content;

    border: 2px dashed var(--color-dark);
    border-radius: 200px;

    font-size: clamp(0.8rem, 6vw, 1.1rem);
    font-weight: 500;

    text-wrap: nowrap;

    cursor: pointer;

    svg {
        vertical-align: text-bottom;
    }
}

.active {
    background: var(--color-darkest);
    color: var(--color-accent);

    border-color: var(--color-darkest);
}
