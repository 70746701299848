:root {
  --color-light: oklch(97.94% .0148 222);
  --color-accent: oklch(91.61% .0443 256);
  --color-accent-2: oklch(91.76% .0444 245.65);
  --color-dark: oklch(77.08% .0235 58);
  --color-darker: oklch(39.47% .0309 293 / .2);
  --color-darkest: oklch(16.47% .0611 254);
  --page-padding: .65rem;
  --border-radius: .5rem;
}

@supports not (color: oklch(0% 0 0deg)) {
  :root {
    --color-light: #f0fcff;
    --color-accent: #d6ebff;
    --color-accent-2: #f0feff;
    --color-dark: #c0b0a5;
    --color-darker: #47435633;
    --color-darkest: #070e18;
  }
}

body {
  color: var(--color-darkest);
  background-color: var(--color-darkest);
  font-family: Akzidenz, sans-serif;
  overflow-x: hidden;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* ::selection {
  color: var(--color-light);
  background: var(--color-darkest);
}

a {
  all: unset;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-darkest) !important;
}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

@font-face {
  font-family: Akzidenz;
  src: url("AKZIDENZGROTESKPRO-REGULAR.fcd9dacd.TTF");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Akzidenz;
  src: url("AKZIDENZGROTESKPRO-MEDIUM.223df8ed.TTF");
  font-weight: 500;
  font-display: swap;
}

.navbar {
  z-index: 99;
  contain: content;
  background: #0006;
  position: fixed;
  inset: 0;
}

.navbar__container {
  z-index: 101;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  max-width: 500px;
  padding: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (width <= 930px) {
  .navbar__content {
    width: 400px;
  }
}

@media only screen and (width <= 538px) {
  .navbar__content {
    width: 90vw;
  }
}

.navbar__title {
  color: var(--color-light);
  text-align: center;
  letter-spacing: -.125rem;
  text-wrap: nowrap;
  margin: .6rem;
  font-size: clamp(2rem, 4vw, 2.6rem);
  font-weight: 500;
  line-height: .925;
}

.navbar__text {
  color: var(--color-light);
  text-align: center;
  letter-spacing: -.0125rem;
  font-size: clamp(.95rem, 2vw, 1.05rem);
  font-weight: 500;
  line-height: .985;
}

.navbar hr {
  background: var(--color-light);
  border: none;
  width: 90%;
  height: 1px;
  margin: 0;
}

.navbar__links {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  margin: 0;
  list-style: none;
  display: flex;
}

.navbar__link {
  background: var(--color-light);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  padding: .3rem .5rem;
  font-size: .85rem;
  font-weight: 500;
  line-height: 0;
  display: flex;
  color: var(--color-darkest) !important;
}

.navbar__toggle-container {
  position: relative;
}

.navbar__toggle {
  cursor: pointer;
  pointer-events: auto;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  padding: .3rem;
  display: flex;
}

.navbar__toggle svg {
  color: var(--color-light);
  transition: transform .2s ease-in-out;
}

.navbar__active svg {
  transform: rotate(180deg);
}

.navbar__wave {
  color: var(--color-light);
  pointer-events: none;
  z-index: 100;
  animation: 2s ease-in-out infinite wiggle;
  position: absolute;
  top: 40%;
  left: 50%;
}

@keyframes wiggle {
  0% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(-15deg);
  }
}

.navbar__blur {
  --layers: 5;
  pointer-events: none;
  z-index: 100;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
}

.navbar__layer {
  --blur: calc(sin(((var(--layers)  - var(--index)) / var(--layers)) * 90deg) * 60);
  --stop: calc(sin(((var(--index)) / var(--layers)) * 90deg) * 15);
  backdrop-filter: blur(calc(var(--blur) * 1px)) saturate(160%);
  mask: radial-gradient(1800px 1800px at 50% 0%, #fff 15%, #0000 calc((15 + var(--stop)) * 1%));
  backface-visibility: hidden;
  background: #9999990d;
  animation: 5s ease-in-out infinite hue;
  position: absolute;
  inset: 0;
}

@keyframes hue {
  0% {
    filter: hue-rotate();
  }

  50% {
    filter: hue-rotate(100deg);
  }

  100% {
    filter: hue-rotate();
  }
}

@media only screen and (width <= 930px) {
  .navbar__layer {
    mask: radial-gradient(1600px 1600px at 50% 0%, #fff 15%, #0000 calc((15 + var(--stop)) * 1%));
  }
}

@media only screen and (width <= 538px) {
  .navbar__layer {
    mask: radial-gradient(400% 200% at 50% 0%, #fff 15%, #0000 calc((15 + var(--stop)) * 1%));
  }
}

.chips {
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem;
  margin-top: 2rem;
  display: flex;
}

.chip {
  padding: calc(var(--page-padding) / 3) var(--page-padding);
  background: var(--color-light);
  border: 2px dashed var(--color-dark);
  text-wrap: nowrap;
  cursor: pointer;
  border-radius: 200px;
  width: min-content;
  margin: 0;
  font-size: clamp(.8rem, 6vw, 1.1rem);
  font-weight: 500;
  text-decoration: none;
}

.chip svg {
  vertical-align: text-bottom;
}

.active {
  background: var(--color-darkest);
  color: var(--color-accent);
  border-color: var(--color-darkest);
}

.slide {
  border-radius: inherit;
  background: var(--color-darkest);
  border-radius: inherit;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.slide__container {
  border-radius: inherit;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  place-items: center;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

.slide__content {
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;
  backface-visibility: hidden;
  grid-area: 1 / 1 / -1 / -1;
  place-items: center;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
}

.slide__current {
  pointer-events: auto;
  opacity: 1;
}

.slide__img {
  border-radius: inherit;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-color: #b4b4b4;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.slide__img video {
  aspect-ratio: 2;
  width: 100%;
  height: 100%;
}

.slide__nav {
  padding: calc(var(--page-padding) * 2);
  background: linear-gradient(0deg, var(--color-darkest) -50%, #0000);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  z-index: 100;
  justify-content: space-between;
  align-self: start;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

.slide__nav__item {
  background: var(--color-light);
  aspect-ratio: 1;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  place-items: center;
  padding: 2rem;
  transition: all .2s;
  display: grid;
}

.slide__nav__item svg {
  color: var(--color-darkest);
  height: 56px;
}

.slide__nav__item:hover {
  background-color: var(--color-darkest);
}

.slide__nav__item:hover svg {
  color: var(--color-accent);
}

@media screen and (width <= 672px) {
  .slide {
    height: 100%;
  }

  .slide__nav {
    padding: var(--page-padding);
  }

  .slide__nav__item {
    padding: 1.5rem;
  }

  .slide__nav__item svg {
    height: 45px;
  }
}

.footer {
  padding: var(--page-padding);
  width: 100%;
  position: relative;
}

.footer__column {
  background: var(--color-accent);
  border-radius: var(--border-radius);
  padding: var(--page-padding);
  padding-bottom: calc(var(--page-padding) * 2);
}

.footer__row {
  justify-content: space-between;
  align-items: end;
  display: flex;
}

.footer__nav {
  background: var(--color-light);
  border-radius: var(--border-radius);
  padding: var(--page-padding);
  align-items: center;
  gap: .75rem;
  width: min-content;
  display: flex;
}

.footer__nav .chip:hover {
  background: var(--color-darkest);
  color: var(--color-accent);
  border-color: var(--color-darkest);
}

.footer__title {
  margin: 0;
  margin-top: calc(var(--page-padding));
  letter-spacing: -.25rem;
  text-wrap: nowrap;
  font-size: clamp(4.5rem, 7vw, 5.75rem);
  font-weight: 500;
  line-height: .8;
}

.footer__title span {
  visibility: hidden;
  font-weight: 400;
}

.footer__picker {
  border-radius: var(--border-radius);
  outline: 3px solid var(--color-light);
  animation: rainbow var(--animation-duration) ease-in-out infinite;
  --animation-duration: 5s;
  cursor: pointer;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3) 0 0 / 1500% 1500%;
  width: 16px;
  height: 16px;
  position: relative;
}

.footer__picker:hover {
  --animation-duration: 2s;
}

.footer__picker span {
  z-index: 100;
  backdrop-filter: blur(10px) saturate(280%);
  border-radius: inherit;
  padding: var(--page-padding);
  opacity: 0;
  background: #ffffffbf;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  display: flex;
  position: absolute;
  bottom: 50%;
  right: 0;
  overflow: hidden;
  transform: translate(0, 50%);
}

@keyframes rainbow {
  0% {
    background-position: 0 82%;
  }

  50% {
    background-position: 100% 19%;
  }

  100% {
    background-position: 0 82%;
  }
}

.footer .active {
  --animation-duration: 2s;
}

.footer__wave {
  color: var(--color-darkest);
  animation: 2s ease-in-out infinite wiggle;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

@media screen and (width <= 930px) {
  .footer__text {
    display: none;
  }
}

@media screen and (width <= 672px) {
  .footer__row:nth-of-type(2) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .footer__wave {
    display: none;
  }

  .footer__picker {
    margin-top: .5rem;
  }
}

@media screen and (width <= 538px) {
  .footer__title {
    margin-top: var(--page-padding);
    text-wrap: wrap;
  }

  .footer__nav {
    min-width: 100%;
    overflow-x: scroll;
  }
}

.copyright {
  top: var(--page-padding);
  left: var(--page-padding);
  z-index: -1;
  justify-content: center;
  align-items: center;
  gap: .2rem;
  display: flex;
  position: fixed;
}

.copyright__text {
  color: var(--color-dark);
  opacity: .5;
  font-size: .75rem;
  font-weight: 400;
}

.copyright__symbol {
  color: var(--color-dark);
  opacity: .4;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 0;
}

.wrap {
  padding: 0 var(--page-padding) 0 var(--page-padding);
  justify-content: center;
  align-items: center;
  gap: calc(var(--page-padding) * 2);
  flex-direction: column;
  display: flex;
}

.wrap .content {
  --shadowpercent: -1%;
  --shadowoffset: 0%;
  top: var(--page-padding);
  height: calc(100vh - var(--page-padding) * 2);
  border-radius: var(--border-radius);
  will-change: transform;
  backface-visibility: hidden;
  contain: content;
  justify-content: space-between;
  gap: var(--page-padding);
  background: var(--color-light);
  padding: var(--page-padding);
  width: 100%;
  display: flex;
  position: sticky;
  overflow: hidden;
}

.wrap .content:after {
  content: "";
  z-index: 1000;
  pointer-events: none;
  background: linear-gradient(to top, var(--color-darker), var(--color-darker) var(--shadowpercent), transparent calc(var(--shadowpercent)  + var(--shadowoffset)), transparent);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wrap .content__container {
  background: linear-gradient(0deg, var(--color-accent), var(--color-accent-2));
  padding: var(--page-padding);
  border-radius: inherit;
  flex-direction: column;
  justify-content: space-between;
  width: min-content;
  display: flex;
  position: relative;
}

.wrap .content__column {
  gap: var(--page-padding);
  border-radius: inherit;
  padding: calc(var(--page-padding) * 2);
  flex-direction: column;
  display: flex;
}

.wrap .content__column:nth-child(2) {
  background: var(--color-light);
}

.wrap .content__more {
  display: none;
}

.wrap .content__title {
  letter-spacing: -.125rem;
  text-wrap: nowrap;
  margin: 0;
  font-size: clamp(2rem, 6vw, 3rem);
  font-weight: 500;
  line-height: 1;
}

.wrap .content__title:first-of-type {
  margin-bottom: calc(var(--page-padding) / 2);
}

.wrap .content__text {
  letter-spacing: -.0125rem;
  margin: 0;
  font-size: clamp(.85rem, 4vw, 1.15rem);
  font-weight: 500;
  line-height: 1.1;
}

.wrap .content__text span {
  font-weight: 400;
}

.wrap .content hr {
  border: none;
  border-top: 2px solid var(--color-darkest);
  opacity: .5;
  width: 100%;
  margin: .25rem;
}

@media screen and (width <= 930px) {
  .content__title {
    min-width: 300px;
    text-wrap: pretty !important;
  }
}

@media screen and (width <= 672px) {
  .wrap .content {
    flex-direction: column;
    width: 100%;
  }

  .wrap .content__container {
    width: 100%;
  }

  .wrap .content__title {
    letter-spacing: -.08rem;
  }

  .wrap .content__column {
    padding: var(--page-padding);
    gap: .15rem;
    max-height: 200px;
    overflow: hidden;
  }

  .wrap .content__column:nth-child(2) {
    position: relative;
  }

  .wrap .content__column:nth-child(2):after {
    content: "";
    background: linear-gradient(0deg, var(--color-light) 10%, transparent);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .wrap .content__more {
    left: 50%;
    bottom: calc(var(--page-padding) * 2);
    color: var(--color-darkest);
    width: calc(100% - var(--page-padding) * 4);
    padding: var(--page-padding);
    border-radius: inherit;
    background: var(--color-accent);
    border: none;
    justify-content: center;
    align-items: center;
    gap: .25rem;
    display: flex;
    position: absolute;
    transform: translateX(-50%);
  }

  .wrap .content__more svg {
    color: var(--color-darkest);
  }

  .wrap .content__more .content__text {
    font-family: Akzidenz;
    font-size: 1rem;
    font-weight: 500;
  }
}
/*# sourceMappingURL=index.e991339e.css.map */
