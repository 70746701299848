.navbar {
    position: fixed;
    inset: 0;

    z-index: 99;

    background: hsla(0, 0%, 0%, 0.4);

    contain: content;

    &__container {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        top: 0;

        z-index: 101;

        max-width: 500px;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 1.25rem;

        padding: 1rem;
    }

    &__content {
        @media only screen and (max-width: 930px) {
            width: 400px;
        }

        @media only screen and (max-width: 538px) {
            width: 90vw;
        }
    }

    &__title {
        color: var(--color-light);

        font-size: clamp(2rem, 4vw, 2.6rem);
        font-weight: 500;

        text-align: center;

        letter-spacing: -0.125rem;
        line-height: 0.925;

        text-wrap: nowrap;

        margin: 0.6rem;
    }

    &__text {
        color: var(--color-light);

        font-size: clamp(0.95rem, 2vw, 1.05rem);
        font-weight: 500;

        text-align: center;

        letter-spacing: -0.0125rem;
        line-height: 0.985;
    }

    hr {
        width: 90%;
        height: 1px;

        background: var(--color-light);

        border: none;

        margin: 0;
    }

    &__links {
        display: flex;
        justify-content: center;
        align-items: center;

        list-style: none;

        margin: 0;

        gap: 0.75rem;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;

        gap: 0.25rem;

        color: var(--color-darkest) !important;
        background: var(--color-light);

        font-size: 0.85rem;
        font-weight: 500;

        padding: 0.3rem 0.5rem;

        border-radius: 0.5rem;

        line-height: 0;
    }

    &__toggle-container {
        position: relative;
    }

    &__toggle {
        padding: 0.3rem;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        pointer-events: auto;

        aspect-ratio: 1 / 1;

        svg {
            transition: transform 0.2s ease-in-out;
            color: var(--color-light);
        }
    }

    &__active svg {
        transform: rotate(180deg);
    }

    &__wave {
        position: absolute;
        top: 40%;
        left: 50%;

        color: var(--color-light);

        pointer-events: none;

        z-index: 100;

        animation: wiggle 2s ease-in-out infinite;

        @keyframes wiggle {
            0% {
                transform: rotate(-15deg);
            }
            50% {
                transform: rotate(10deg);
            }
            100% {
                transform: rotate(-15deg);
            }
        }
    }

    &__blur {
        --layers: 5;
        position: fixed;
        bottom: 0;

        height: 100vh;
        width: 100vw;

        pointer-events: none;

        z-index: 100;
        inset: 0;
    }

    &__layer {
        --blur: calc(
            sin(((var(--layers) - var(--index)) / var(--layers)) * 90deg) * 60
        );
        --stop: calc(sin(((var(--index)) / var(--layers)) * 90deg) * 15);
        position: absolute;
        inset: 0;
        background: hsl(0 0% 60% / 0.05);
        backdrop-filter: blur(calc(var(--blur) * 1px)) saturate(160%);

        mask: radial-gradient(
            1800px 1800px at 50% 0%,
            #fff 15%,
            #0000 calc((15 + var(--stop)) * 1%)
        );

        backface-visibility: hidden;

        animation: hue 5s infinite ease-in-out;

        @keyframes hue {
            0% {
                filter: hue-rotate(0deg);
            }

            50% {
                filter: hue-rotate(100deg);
            }

            100% {
                filter: hue-rotate(0deg);
            }
        }

        @media only screen and (max-width: 930px) {
            mask: radial-gradient(
                /* Widht */ /* Height */ /* Right */ /* Top */ 1600px 1600px at
                    50% 0%,
                #fff 15%,
                #0000 calc((15 + var(--stop)) * 1%)
            );
        }

        @media only screen and (max-width: 538px) {
            mask: radial-gradient(
                /* Widht */ /* Height */ /* Right */ /* Top */ 400% 200% at 50%
                    0%,
                #fff 15%,
                #0000 calc((15 + var(--stop)) * 1%)
            );
        }
    }
}
