.slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: inherit;
    width: 100%;

    background: var(--color-darkest);
    border-radius: inherit;

    &__container {
        position: relative;

        border-radius: inherit;

        height: 100%;
        overflow: hidden;

        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 100%;
        place-items: center;
    }

    &__content {
        width: 100%;
        height: 100%;

        border-radius: inherit;

        grid-area: 1 / 1 / -1 / -1;
        pointer-events: none;

        opacity: 0;
        overflow: hidden;

        position: relative;
        display: grid;
        place-items: center;

        will-change: transform, opacity;
        backface-visibility: hidden;
    }

    &__current {
        pointer-events: auto;
        opacity: 1;
    }

    &__img {
        width: 100%;
        height: 100%;

        position: relative;

        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: #b4b4b4;
        border-radius: inherit;

        will-change: transform, opacity, filter;
        backface-visibility: hidden;

        video {
            width: 100%;
            height: 100%;

            aspect-ratio: 2;
        }
    }

    &__nav {
        position: absolute;
        bottom: 0;

        padding: calc(var(--page-padding) * 2);
        width: 100%;

        background: linear-gradient(
            0deg,
            var(--color-darkest) -50%,
            rgba(0, 0, 0, 0)
        );
        border-radius: 0 0 var(--border-radius) var(--border-radius);

        z-index: 100;

        display: flex;
        justify-content: space-between;
        align-self: start;
    }

    &__nav__item {
        border: 0;
        background: var(--color-light);
        padding: 2rem;

        aspect-ratio: 1;
        border-radius: 50%;

        display: grid;
        place-items: center;

        transition: all 0.2s;

        cursor: pointer;

        svg {
            color: var(--color-darkest);
            height: 56px;
        }
    }

    &__nav__item:hover {
        background-color: var(--color-darkest);
        svg {
            color: var(--color-accent);
        }
    }
}

@media screen and (max-width: 930px) {
    // Add tablet styles here
}

@media screen and (max-width: 672px) {
    .slide {
        height: 100%;

        &__nav {
            padding: var(--page-padding);
        }

        &__nav__item {
            padding: 1.5rem;

            svg {
                height: 45px;
            }
        }
    }
}

@media screen and (max-width: 538px) {
    // Add mobile styles here
}
