@use "variables/global";
@use "components/navbar";
@use "components/chip";
@use "components/slide";
@use "components/footer";
@use "components/copyright";

.wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 var(--page-padding) 0 var(--page-padding);
    gap: calc(var(--page-padding) * 2);

    .content {
        --shadowpercent: -1%;
        --shadowoffset: 0%;

        position: sticky;

        top: var(--page-padding);

        width: 100%;
        height: calc(100vh - calc(var(--page-padding) * 2));

        border-radius: var(--border-radius);

        will-change: transform;
        backface-visibility: hidden;
        contain: content;

        display: flex;
        justify-content: space-between;
        gap: var(--page-padding);
        background: var(--color-light);

        padding: var(--page-padding);

        overflow: hidden;

        &::after {
            content: "";

            position: absolute;
            z-index: 1000;

            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;

            pointer-events: none;

            background: linear-gradient(
                to top,
                var(--color-darker),
                var(--color-darker) var(--shadowpercent),
                transparent calc(var(--shadowpercent) + var(--shadowoffset)),
                transparent
            );
        }

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            position: relative;

            background: linear-gradient(
                0deg,
                var(--color-accent),
                var(--color-accent-2)
            );
            padding: var(--page-padding);

            border-radius: inherit;
            width: min-content;
        }

        &__column {
            display: flex;
            flex-direction: column;
            gap: var(--page-padding);

            border-radius: inherit;
            padding: calc(var(--page-padding) * 2);
        }

        &__column:nth-child(2) {
            background: var(--color-light);
        }

        &__more {
            display: none;
        }

        &__title {
            margin: 0;

            font-size: clamp(2rem, 6vw, 3rem);
            font-weight: 500;

            letter-spacing: -0.125rem;
            line-height: 1;

            text-wrap: nowrap;
        }

        &__title:first-of-type {
            margin-bottom: calc(var(--page-padding) / 2);
        }

        &__text {
            margin: 0;

            font-size: clamp(0.85rem, 4vw, 1.15rem);
            font-weight: 500;

            letter-spacing: -0.0125rem;
            line-height: 1.1;

            span {
                font-weight: 400;
            }
        }

        hr {
            width: 100%;
            border: none;
            border-top: 2px solid var(--color-darkest);

            margin: 0.25rem;

            opacity: 0.5;
        }
    }
}

@media screen and (max-width: 930px) {
    .content {
        &__title {
            text-wrap: pretty !important;
            min-width: 300px;
        }
    }
}

@media screen and (max-width: 672px) {
    .wrap {
        .content {
            flex-direction: column;
            width: 100%;
            &__container {
                width: 100%;
            }

            &__title {
                letter-spacing: -0.08rem;
            }

            &__column {
                padding: var(--page-padding);
                max-height: 200px;
                gap: 0.15rem;

                overflow: hidden;
            }

            &__column:nth-child(2) {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                        0deg,
                        var(--color-light) 10%,
                        transparent
                    );
                }
            }

            &__more {
                position: absolute;
                left: 50%;
                bottom: calc(var(--page-padding) * 2);
                transform: translateX(-50%);

                color: var(--color-darkest);

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.25rem;

                width: calc(100% - calc(var(--page-padding) * 4));

                padding: var(--page-padding);
                border-radius: inherit;

                background: var(--color-accent);
                border: none;

                svg {
                    color: var(--color-darkest);
                }

                .content__text {
                    font-size: 1rem;
                    font-weight: 500;
                    font-family: Akzidenz;
                }
            }
        }
    }
}

@media screen and (max-width: 538px) {
    // Add mobile styles here
}
