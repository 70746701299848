.copyright {
    position: fixed;
    top: var(--page-padding);
    left: var(--page-padding);

    z-index: -1;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    &__text {
        font-size: 0.75rem;
        font-weight: 400;

        color: var(--color-dark);
        opacity: 0.5;
    }

    &__symbol {
        font-size: calc(0.75rem * 2.5);
        font-weight: 400;

        line-height: 0;

        color: var(--color-dark);
        opacity: 0.4;
    }
}
